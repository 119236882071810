/**USER**/

.user-component {
    div[class*=col] {
        background-color: transparent;
    }

    .create-user-form {
        padding: 40px 100px;
    }

    .create-user-form .error {
        border-color: #f00 !important;
        color: #f00 !important;
    }
}

