
// EVENTS COMPONENT
.events-component {
    .createRoom {
        width: 80%;
        margin: auto;

        .input-evtname {
            background-color: #fff !important;
            height: 33px;
        }

        .ant-calendar-picker {
            width: 100% !important;
        }

        .error {
            border: 1px solid red;
            color: #f00;
            border-radius: 5px;
        }

        input.error::placeholder,
        .error .ant-select-selection__placeholder {
            color: red;
        }
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 16px 6px;
        text-align: center;
    }

}
