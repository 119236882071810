/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
*/

@font-face {
    //font-family: 'wt_gothiclight';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-Light.woff2') format('woff2'),
    url('../../fonts/WTGothic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

/*
@font-face {
    //font-family: 'wt_gothiclight_italic';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-LightItalic.woff2') format('woff2'),
    url('../../fonts/WTGothic-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
*/


@font-face {
    //font-family: 'wt_gothicregular';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-Regular.woff2') format('woff2'),
    url('../../fonts/WTGothic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    //font-family: 'wt_gothicregular_italic';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-RegularItalic.woff2') format('woff2'),
    url('../../fonts/WTGothic-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    //font-family: 'wt_gothicmedium';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-Medium.woff2') format('woff2'),
    url('../../fonts/WTGothic-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/*
@font-face {
    //font-family: 'wt_gothicmedium_italic';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-MediumItalic.woff2') format('woff2'),
    url('../../fonts/WTGothic-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
*/

/*
@font-face {
    //font-family: 'wt_gothicsemi-bold';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-SemiBold.woff2') format('woff2'),
    url('../../fonts/WTGothic-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    //font-family: 'wt_gothicsemi-bold_italic';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-SemiBoldItalic.woff2') format('woff2'),
    url('../../fonts/WTGothic-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
*/


@font-face {
    //font-family: 'wt_gothicbold';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-Bold.woff2') format('woff2'),
    url('../../fonts/WTGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

/*
@font-face {
    //font-family: 'wt_gothicbold_italic';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-BoldItalic.woff2') format('woff2'),
    url('../../fonts/WTGothic-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    //font-family: 'wt_gothicregular_variable';
    font-family: 'WT-Gothic';
    src: url('../../fonts/WTGothic-variable.woff2') format('woff2'),
    url('../../fonts/WTGothic-variable.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
*/
