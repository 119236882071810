@import "./partials/variables";

// color palette

.cyan {
    color: $cyan;
}

.white {
    color: $white;
}

.focus-color {
  color: $vividYellow; // focus
}

.inspiration-color {
  color: $vividOrange; // inspiration
}

.relevance-color {
  color: $vividBlue; // relevance
}

.effect-color {
  color: $darkModeratePink; //effect
}

.gray {
  color: $gray;
}
