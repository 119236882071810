@import "../partials/fonts";
@import "../partials/variables";


/*
|--------------------------------------------------------------------------
| COMMON STYLES for frontuser
|--------------------------------------------------------------------------
*/

.head {
    direction: rtl;

    .head-title {
        height: auto;
        width: 100%;
        display: block;
        text-align: center;
        color: #fff;
        font-size: 1rem;
    }

    .box {
        direction: ltr;
    }
}

.ant-btn.btn-orange {
    font-size: 1.3rem;
    font-weight: $font-weight-medium;

    border: 0;

    color: $white;
    background-color: $orange;//#f79228;

    text-transform: capitalize;

    padding: 0 3rem !important;
}

.ant-btn-link.btn-leave {
  background-image: url('../../images/btn-leave.png');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 18%;
  padding-left: 25px;
  color: $gray2;


  &:focus,
  &:hover {
    background-image: url('../../images/btn-leave.png');
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 18%;
    padding-left: 25px;
    color: $gray2;
    text-decoration: underline;
    
    span {
      text-decoration: underline;
    }
  }
}
