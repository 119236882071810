
.profile {
    color: #fff;

    .ant-drawer-body {
        padding: 5px;
    }

    .anticon {
        vertical-align: text-top;
    }

    .register {
        &.container {
            min-height: initial;

            .login-form {
                width: 250px;
                border: none;
                background: none;
                padding: 0;
            }

            .ant-btn-primary {
                margin: 0;
            }
        }
    }

    .logoff {
        margin: 15px;
    }
}

