
// EVENT COMPONENT
.event-component {
    div[class*=col] {
        background-color: #fff;
    }

    .event-collapse {
        .ant-collapse-item > .ant-collapse-header {
            color: white;
            background-color: #454e9c;
        }

        div.ant-table-column-sorters {
            padding: 0;
            justify-content: space-between;
            background-color: #fafafa;
        }

        .table-ideas-container .ant-table {
            margin-top: 30px;
            border-radius: 0;
            border: 1px solid #ccc;
        }

        .table-ideas-container .ant-table-thead tr,
        .table-ideas-container .ant-table-tbody tr {
            border-bottom: 1px solid #ccc;
            display: block;
        }

        .ideas-section .ant-table table {
            border-collapse: collapse;
        }

        .table-ideas-container .ant-table-thead > tr > th,
        .table-ideas-container .ant-table-tbody > tr > td {
            padding: 10px 10px;
            text-align: center;
            white-space: nowrap;
            width: 16%;
            overflow: hidden;
            text-overflow: ellipsis;
            border: none;
            height: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: auto;
            text-align: center;
        }

        .table-ideas-container .ant-table-thead tr th {
            border-right: 1px solid #ccc;
        }

        .table-ideas-container .ant-table-thead th:first-child {
            width: 35%;
        }

        .table-ideas-container .ant-table-thead th:nth-of-type(2),
        .table-ideas-container .ant-table-tbody td:nth-of-type(2) {
            width: 20%;
        }

        .table-ideas-container .ant-table-thead th:nth-of-type(3),
        .table-ideas-container .ant-table-tbody td:nth-of-type(3) {
            width: 10%;
        }

        .table-ideas-container .ant-table-thead th:nth-of-type(4),
        .table-ideas-container .ant-table-tbody td:nth-of-type(4) {
            width: 8%;
        }

        .table-ideas-container .ant-table-thead th:nth-of-type(5),
        .table-ideas-container .ant-table-tbody td:nth-of-type(5) {
            width: 18%;
        }

        .table-ideas-container .ant-table-thead th:last-child,
        .table-ideas-container .ant-table-tbody td:last-child {
            width: 9%;
            border: none;
        }

        .table-ideas-container .ant-table-tbody td:first-child {
            justify-content: flex-start;
            width: 35%;
        }

        .table-ideas-container .ant-table-tbody .ant-btn {
            padding: 0;
        }

        .table-ideas-container .ant-table-tbody .ant-btn .ant-tag{
            margin: 0;
            height: 100%;
            line-height: 2em;
        }
    }


    .offices-collapse .ant-collapse-content-box {
        padding: 0;
    }

    .offices-collapse .ant-collapse-content-box .ant-table {
        border: none;
    }

    .add-idea-container {
        padding: 0px 0 20px 0;
    }

    .add-idea-container .row {
        margin: auto;
    }

    .add-idea-container .select-office .ant-select-selection--single {
        height: 38px;
    }

    .add-idea-container .anticon.anticon-plus-circle {
        font-size: 38px;
    }

    .offices-table .ant-table-thead th:last-child,
    .offices-table .ant-table-tbody td:last-child {
        text-align: right;
    }

    .addOffice {
        margin: auto;
        font-size: 30px;
        padding: 0 0 0 0;
        display: block;
    }



    .clients-section .ant-table-filter-column-title {
        padding: 5px 15px 5px 0px;
        background-color: #fafafa;
    }
    .clients-section .ant-table-filter-column {
        margin: auto;
    }

    .clients-section .ant-table {
        background-color: #fff;
        border: 1px solid #ccc;
        border-collapse: unset;
    }

    .clients-section .ant-table-thead > tr > th,
    .clients-section .ant-table-tbody > tr > td {
        border-right: 1px solid #ccc;
        padding-left: 15px;
        padding-right: 15px;
    }


    .clients-section .ant-table-thead > tr > th:last-child,
    .clients-section .ant-table-tbody > tr > td:last-child {
        border-right: none;
    }
    .clients-section .ant-table-thead > tr > th,
    .clients-section .ant-table-tbody > tr > td {
        border-bottom: 1px solid #ccc;
        text-align: center;
    }

    .clients-section .ant-table span b {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
        position: relative;
        top: 5px;
        right: -10px;
    }

    .clients-section .ant-table .state-entering b {
        background-color:  #2196F3;
    }
    .clients-section .ant-table .state-entering {
        color: #2196F3
    }

    .clients-section .ant-table .state-enabled b {
        background-color: #4CAF50;;
    }
    .clients-section .ant-table .state-enabled {
        color: #4CAF50;
    }

    .clients-section .ant-table .state-disconnected b {
        background-color: #F44336;;
    }
    .clients-section .ant-table .state-disconnected {
        color: #F44336;
    }

    .clients-section .ant-table .state-voting b {
        background-color: #FF9800;;
    }
    .clients-section .ant-table .state-voting {
        color: #FF9800;
    }

    .clients-section .ant-table .state-voted b {
        background-color: #009688;;
    }
    .clients-section .ant-table .state-voted {
        color: #009688;
    }

    .clients-section .ant-table .state-waiting b {
        background-color: #544687;;
    }
    .clients-section .ant-table .state-waiting {
        color: #544687;
    }

    .clients-section .ant-table .state-disabled b {
        background-color: #ccc;;
    }
    .clients-section .ant-table .state-disabled {
        color: #ccc;
    }

    .code-container {
        display: flex;
    }

    .code-container button {
        justify-content: center;
        width: 100%;
        align-items: center;
        height: 32px;
        display: flex;
        font-size: 17px;
        margin-top: 5px;
        border-color: #fff;
    }

    .code-container .access-code {
        font-size: 40px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid rgb(24, 144, 255);
        padding: 7px 19px;
        color: rgb(24, 144, 255);
        display: flex;
        margin: 5px 0 5px 5px;
        justify-content: center;
        width: 100%;
        align-items: center;
        height: 70px;
    }

    .code-container .access-code b {
        font-size: 20px;
    }

    .showResultsContainer {
        display: flex;
        justify-content: space-between;
    }

    .showResultsContainer button {
        padding: 0 7px;
        width: 20%;
        margin: 10px 0;
        border: 1px solid #fff;

    }

    .dateContainer {
        display: flex;
    }

    .dateContainer .ant-statistic-content-value {
        color: #b93973;

    }
    .rangeDate {
        width: 444px;
    }

    .date-error .rangeDate {
        color: #b93973;
        border-color: #b93973;
    }

    .dateContainer button {
        border: 1px solid #fff;
        margin-left: 30px;
    }
}
