@import "./partials/fonts";
@import "./partials/variables";


/*
|--------------------------------------------------------------------------
| COMMON STYLES for frontuser, dashboard and login
|--------------------------------------------------------------------------
*/

html, body {
    margin: 0;
    border: 0;
    padding: 0;
}

body {
    position: relative;

    font-family: $fontWTGothic !important;
    font-size: 16px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: $overScrool !important;

    &.scroll {
      #root {
        height: auto !important;
      }
    }
}


#root {
    position: relative;
    width: 100%;
    height: 100%;
}


// body,
.container:not(.register) {
    display: flex;
    flex-direction: column;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $fontWTGothic;
    font-weight: bold;
}


// todo, move to frontuser?
@media (min-width: 576px) {
    .head {
        max-height: 260px;
    }
    .container {
        min-height: 680px;
    }
}


// Matches all input fields of login, dashboard and frontuser.
// Each app can then customize further.
input[class*=form-control] {
    color: #bcbcbc !important;
    border-bottom: 1px solid #bcbcbc;
    background-color: transparent !important;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.2rem;
}


.full-viewport {
    position: relative;
    width: 100%;
    height: 100%;
}

.background-mesh {
    border-style: solid;
    border-width: 33px;
    border-image-source: url(../images/mesh_border.png);
    border-image-repeat: round;

    // All sides
    border-image-slice: 32 fill;
}
