/*
|--------------------------------------------------------------------------
| Color palette
|--------------------------------------------------------------------------
*/




$radioYellow0: #ffed9f;
$radioYellow1: #ffe578;
$radioYellow2: #ffdd4d;
$radioYellow3: #ffd822;

$radioOrange0: #fc8d6b;
$radioOrange1: #f97e53;
$radioOrange2: #f9733e;
$radioOrange3: #f16522;

$radioBlue0: #69a0f9;
$radioBlue1: #4780f4;
$radioBlue2: #2a67f2;
$radioBlue3: #0a47ed;

$radioPink0: #db82b5;
$radioPink1: #cc699d;
$radioPink2: #bf5588;
$radioPink3: #af3b6e;

$white: #fff;
$orange: #f16522;
$gray: #a8a8a8;
$gray1: #545454;
$gray2: #d6d6d6;

$cyan: #00d1da;
$red: #f00;

// new colors
$vividYellow: #ffd822; // focus
$vividOrange: #f16522; // inspiration
$vividBlue: #0a47ed; // relevance
$darkModeratePink: #af3b6e; //effect

$overScrool: #231f20;
$veryDarkBlue: #001c30;


/*
|--------------------------------------------------------------------------
| Font Families
|--------------------------------------------------------------------------
*/

$fontWTGothic: 'WT-Gothic', Arial, Helvetica, sans-serif;

$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-medium: 500;
$font-weight-bold: bold;
$font-weight-black: 900;

/*
|--------------------------------------------------------------------------
| Icons
|--------------------------------------------------------------------------
*/




/*
|--------------------------------------------------------------------------
| Sizes
|--------------------------------------------------------------------------
*/
