@import "../partials/fonts";
@import "../partials/variables";

/*
|--------------------------------------------------------------------------
| COMMON STYLES for dashboard
|--------------------------------------------------------------------------
*/

body {
    overflow-x: hidden;
}

.header,
.top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-menu {
    .ant-layout-sider-zero-width-trigger {
        position: absolute;
        top: -55px;
        right: -36px;
        z-index: 1;
        width: 39px;
        height: 42px;
        color: #fff;
        font-size: 18px;
        line-height: 33px;
        background-color: #2c97cf;
    }
}


.ant-table-filter-column {
    background-color: unset !important;
}

.ant-table-column-sorters {
    background-color: unset !important;
}

.ant-table-column-sorters-with-tooltip {
    background-color: unset !important;
}

.ant-layout-content .slider-menu {
    min-height: calc(100vh - 64px - 70px); /* - header & footer */
}

.ant-layout-content.user-component .ant-table-cell {
    word-break: break-all;
}

