@import "../../styles/partials/fonts";
@import "../../styles/partials/variables";


/*
|--------------------------------------------------------------------------
| COMMON STYLES for login
|--------------------------------------------------------------------------
*/


.login {

}

.register {

}


.login, .register {
  &.container {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;


    .ant-btn-primary {
      width: 130px;
      margin: 5px;
    }

  }

  .message {
    border: 1px solid;
    border-radius: 25px;
    padding: 10%;
    background-color: #ccc;
    text-align: center;
    color: #000
  }

  .error {
    border-color: #f00 !important;
    color: #f00 !important;
  }

  div[class*=col] {
    background-color: unset;
  }

}


.login-form {
  border: 1px solid;
  border-radius: 25px;
  padding: 5%;
  background-color: #ccc;
}
